import React, { Component } from 'react';
import { BrowserRouter as Router, Route, Link, Redirect, Switch } from "react-router-dom";
import Nav from './Nav';
import './Home.css';
import BuildClient from './BuildClient'
import LoadClient from './LoadClient'
import Armado from './Armado'
import Cargos from './Cargos'
import Prendas from './Prendas'
import Empleados from './Empleados'
import AddTallaje from './AddTallaje'
import Produccion from './Produccion'

class Home extends Component {
    constructor(props){
        super()
        this.state = {
            site: ''
        }
        this.changeSite = this.changeSite.bind(this)
    }
    componentDidMount(){
        if(!localStorage.getItem('token')){
            this.props.history.push("/");
        }
    }
    changeSite(data){
        this.setState({
            site: data
        })
    }
    logout = () => {
        localStorage.clear();
        sessionStorage.clear()
        this.props.history.push("/");
    }
    render() {
        return (
            <div>
                <div className="container-fluid">
                    <nav>
                        <div class="nav nav-tabs" id="nav-tab" role="tablist">
                            <a class="nav-item nav-link active" id="nav-crear-tab" data-toggle="tab" href="#nav-crear" role="tab" aria-controls="nav-crear" aria-selected="true">Clientes</a>
                            <a class="nav-item nav-link" id="nav-cargo-tab" data-toggle="tab" href="#nav-cargo" role="tab" aria-controls="nav-cargo" aria-selected="true">Cargos</a>
                            <a class="nav-item nav-link" id="nav-armar-tab" data-toggle="tab" href="#nav-armar" role="tab" aria-controls="nav-armar" aria-selected="true">Pedido</a>
                            <a class="nav-item nav-link" id="nav-empleado-tab" data-toggle="tab" href="#nav-empleado" role="tab" aria-controls="nav-empleado" aria-selected="true">Empleados</a>
                            <a class="nav-item nav-link" id="nav-asignar-tab" data-toggle="tab" href="#nav-asignar" role="tab" aria-controls="nav-asignar" aria-selected="false">Asignar Asistente</a>
                            <a class="nav-item nav-link" id="nav-prendas-tab" data-toggle="tab" href="#nav-prendas" role="tab" aria-controls="nav-prendas" aria-selected="false">Prendas y Satelites</a>
                            <a class="nav-item nav-link" id="nav-asistente-tab" data-toggle="tab" href="#nav-asistente" role="tab" aria-controls="nav-asistente" aria-selected="false">Asistentes</a>
                            <a class="nav-item nav-link" id="nav-produccion-tab" data-toggle="tab" href="#nav-produccion" role="tab" aria-controls="nav-produccion" aria-selected="false">Producción</a>
                            <a class="nav-item nav-link" id="nav-cerrar-tab" data-toggle="tab" href="#!" role="tab" aria-controls="nav-cerrar" aria-selected="false" onClick={() => this.logout()}>Cerrar Sesion</a>
                        </div>
                    </nav>
                    <div class="tab-content" id="nav-tabContent">
                        <div class="tab-pane fade show active" id="nav-crear" role="tabpanel" aria-labelledby="nav-crear-tab"><BuildClient/></div>
                        <div class="tab-pane fade" id="nav-cargo" role="tabpanel" aria-labelledby="nav-cargo-tab"><Cargos/></div>
                        <div class="tab-pane fade" id="nav-armar" role="tabpanel" aria-labelledby="nav-armar-tab"><Armado/></div>
                        <div class="tab-pane fade" id="nav-empleado" role="tabpanel" aria-labelledby="nav-empleado-tab"><Empleados/></div>
                        <div class="tab-pane fade" id="nav-asignar" role="tabpanel" aria-labelledby="nav-asignar-tab"><LoadClient/></div>
                        <div class="tab-pane fade" id="nav-asistente" role="tabpanel" aria-labelledby="nav-asistente-tab"><AddTallaje/></div>
                        <div class="tab-pane fade" id="nav-prendas" role="tabpanel" aria-labelledby="nav-prendas-tab"><Prendas/></div>
                        <div class="tab-pane fade" id="nav-produccion" role="tabpanel" aria-labelledby="nav-produccion-tab"><Produccion/></div>
                    </div>
                </div>
            </div>
        );
    }
}

export default Home;