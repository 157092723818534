import React, { Component } from 'react';
import Select from 'react-select';

class Empleados extends Component {
    constructor(props){
        super(props)
        this.state = ({
            nombre: '',
            genero: '',
            cedula: '',
            cargo: '',
            idClient: 0,
            clientes: [],
            cliente: null,
            selectClientes: [],
            cargos: [],
            empleado: []
        })
    }
    componentDidMount(){
        this.getClient()
    }
    getClient = () => {
        var datos = {
            dato: 'hola'
        }
        fetch('/getClient', {
            method: 'POST',
            body: JSON.stringify(datos),
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            }
        })
            .then(res => res.json())
            .then((data) => {
                this.setState({
                    clientes: data
                })
            })
            .then(() => {
                this.selectClient()
            })
            .catch(err => console.error(err))
    }
    selectClient = () => {
        var array = []
        this.state.clientes.map(cli => {
            array.push({
                value: cli.id_cliente,
                label: cli.cliente
            })
        })
        this.setState({
            selectClientes: array
        })
    }
    sendExcel = () => {
        var datos = {
            nombre: 'hola'
        }
        fetch('/sendExcel', {
            method: 'POST',
            body: JSON.stringify(datos),
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            }
        })
            .then(res => res.json())
            .then((data) => {
                this.setState({
                    prendas: data
                })
            })
            .catch(err => console.error(err))        
    }
    getCargos(){
        var datos = {
            idCliente: this.state.idClient
        }
        fetch('/getCargo', {
            method: 'POST',
            body: JSON.stringify(datos),
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            }
        })
            .then(res => res.json())
            .then((data) => {
                this.setState({
                    cargos: data
                })
            })
            .catch(err => console.error(err))
    }
    getEmpleado(){
        var datos = {
            idCliente: this.state.idClient
        }
        fetch('/getEmpleado', {
            method: 'POST',
            body: JSON.stringify(datos),
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            }
        })
            .then(res => res.json())
            .then((data) => {
                this.setState({
                    empleado: data
                })
            })
            .catch(err => console.error(err))
    }
    addEmpleado = () => {
        var {idClient, nombre, cedula, genero, cargo} = this.state
        var datos = {
            idClient: idClient,
            nombre: nombre,
            cedula: cedula,
            genero: genero,
            cargo: cargo
        }
        fetch('/addEmpleado', {
            method: 'POST',
            body: JSON.stringify(datos),
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            }
        })
            .then(res => res.json())
            .then((data) => {
                if(data.resp === 'ok'){
                    this.getEmpleado()
                }
            })
            .catch(err => console.error(err))
    }
    deleteEmpleado = (idEmpleado) => {
        var datos = {
            idEmpleado: idEmpleado
        }
        fetch('/deleteEmpleado', {
            method: 'POST',
            body: JSON.stringify(datos),
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            }
        })
            .then(res => res.json())
            .then((data) => {
                if(data.resp === 'ok'){
                    this.getEmpleado()
                }
            })
            .catch(err => console.error(err))
    }
    selectCargo = () => {
        this.getCargos()
        this.getEmpleado()
    }
    xlstojson = () => {
        var datos = {
            name: 'prueba.xlsx'
        }
        fetch('/xlsxToJson', {
            method: 'POST',
            body: JSON.stringify(datos),
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            }
        })
            .then(res => res.json())
            .then((data) => {
                
            })
            .catch(err => console.error(err))
    }
    enviarExcel = e => {
        const data = new FormData(e.target)

        fetch('/xlsxToJson', {
            method: 'POST',
            body: data
        })
            .then(res => res.json())
            .then((data) => {
                if(data.respuesta === 'ok'){
                    alert('todo bien')
                }
            })
            .catch(err => console.error(err))
    }
    render() {
        return (
            <div className="container recuadro">
                <div className="row">
                    <div className="col-12 text-right backLink">
                        <a href="#!" onClick={() => this.getClient()}><i className="fas fa-sync"></i></a>
                    </div>
                </div>
                <div className="row">
                    <div className="col-12 text-center">
                        <h3>Agrega Empleados</h3>
                    </div>
                </div>
                <div className="row">
                    <div className="col-md-6 offset-md-3 text-center">
                        <div className="form-group">
                            <label htmlFor="cliente">Selecciona Cliente</label>
                            <Select
                                options={this.state.selectClientes}
                                value={this.state.cliente}
                                onChange={(value) => {
                                    this.setState({cliente: value, idClient: value.value})
                                }}
                                placeholder='Cliente'
                            />
                            <button className="btn btn-dark mt-2" onClick={() => this.selectCargo()}>Seleccionar</button>
                        </div>
                    </div>
                </div>
                <div className="row">
                    <div className="col-md-6">
                        <p>Id de cliente: {this.state.idClient}</p>
                        <p>Para subir empleados desde un archivo de Excel por favor seleccione un archivo de excel con extensión .xlsx, el archivo debe contener las columnas id de cliente, nombre, cedula, genero y cargo en ese orden y sin cabezera (el id de cliente es el numero que se encuentra arriba)</p>
                    </div>
                    <div className="col-md-6">
                        <form method="post" action="/saveFileTallaje" enctype="multipart/form-data" onSubmit={(e) => {this.enviarExcel(e); e.preventDefault()}}>
                            <input type="file" name="file" className="form-control mt-3"/><br/>
                            <input type="submit" value="Agregar Excel" className="btn btn-dark" onClick={() => this.xlstojson()}/>
                        </form>
                    </div>
                </div>
                <div className="row">
                    <div className="form-group col-md-3">
                        <label htmlFor="nombre">Nombre</label>
                        <input 
                            type="text" 
                            className="form-control" placeholder="Nombre" 
                            id="nombre"
                            value={this.state.nombre}
                            onChange={e => this.setState({nombre: e.target.value})}
                        />
                    </div>
                    <div className="form-group col-md-3">
                        <label htmlFor="cedula">Cedula</label>
                        <input 
                            type="text" 
                            className="form-control" placeholder="cedula" 
                            id="cedula"
                            value={this.state.cedula}
                            onChange={e => this.setState({cedula: e.target.value})}
                        />
                    </div>
                    <div className="form-group col-md-3">
                        <label htmlFor="genero">Genero</label>
                        <select
                            className="form-control"
                            id="genero"
                            value={this.state.genero}
                            onChange={e => this.setState({
                                genero: e.target.value
                            })}
                        >
                            <option value=""></option>
                            <option value="masculino">Masculino</option>
                            <option value="femenino">Femenino</option>
                        </select>
                    </div> 
                    <div className="form-group col-md-3">
                        <label htmlFor="cargo">Cargo</label>
                        <select 
                            type="text" 
                            className="form-control" placeholder="Nombre" 
                            id="cargo"
                            value={this.state.cargo}
                            onChange={e => this.setState({cargo: e.target.value})}
                        >
                            <option value=""></option>
                            {this.state.cargos.map( cargo => {
                                return(
                                    <option key={cargo.id_cargo} value={cargo.cargo}>{cargo.cargo}</option>
                                )
                            })}
                        </select>
                    </div>
                </div>
                <div className="row">
                    <div className="col-12 text-center">
                        {/* <button className="btn btn-outline-dark" onClick={() => this.addEmpleado()}>Agregar</button> */}
                        <button className="btn btn-outline-dark" onClick={() => this.addEmpleado()}>Agregar</button>
                    </div>
                </div>
                <div className="row mt-3">
                    <div className="col-12">
                        <table className="table">
                            <thead>
                                <tr>
                                    <th>Nombre</th>
                                    <th>Cedula</th>
                                    <th>Genero</th>
                                    <th>Cargo</th>
                                </tr>
                            </thead>
                            <tbody>
                                {this.state.empleado.map(employer => {
                                    return(
                                        <tr key={employer.id_empleado}>
                                            <td>{employer.nombre}</td>
                                            <td>{employer.cedula}</td>
                                            <td>{employer.genero}</td>
                                            <td>{employer.cargo}</td>
                                            <td><button onClick={() => this.deleteEmpleado(employer.id_empleado)}><i className="fas fa-trash text-danger"></i></button></td>
                                        </tr>
                                    )
                                })}
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
        );
    }
}

export default Empleados;