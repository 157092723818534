import React, { Component } from 'react';
import { BrowserRouter as Router, Route, Switch} from 'react-router-dom';
import Login from './Login';
import Home from './Home';

class App extends Component {
    render() {
        return (
            <div>
                <Switch>
                    <Route path="/" exact component={Login} />
                    <Route path="/Home" component={Home} />
                </Switch>
            </div>
        );
    }
}

export default App;