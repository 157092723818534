import React, { Component } from 'react';
import ReactExport from "react-export-excel";

const ExcelFile = ReactExport.ExcelFile;
const ExcelSheet = ReactExport.ExcelFile.ExcelSheet;
const ExcelColumn = ReactExport.ExcelFile.ExcelColumn;

class Vercargos extends Component {
    constructor(props){
        super(props)
        this.state = ({
            idCliente: null,
            tallasCargos: [],
        })
    }

    componentDidMount(){
        var idCliente = sessionStorage.getItem('tablesIdClient')
        this.setState({
            idCliente: idCliente
        })
        this.getDataCargos(idCliente)
    }

    getDataCargos = (id) => {
        var data = {
            id: id
        }
        fetch('/getTallasCargos', {
            method: 'POST',
            body: JSON.stringify(data),
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            }
        })
            .then(res => res.json())
            .then((data) => {
                this.setState({
                    tallasCargos: data
                })
            })
            .then(() => {
                this.getAsistent()
                this.getTallado()
            })
            .catch(err => console.error(err))
    }
    render() {
        return (
            <div>
                <div className="row">
                    <div className="col-12">
                        <h4>Consolidado por cargos, tallas y satelites</h4>
                        <ExcelFile element={<button>Descargar Excel</button>}>
                            <ExcelSheet data={this.state.tallasCargos} name="Satelites">
                                <ExcelColumn label="Cargo" value="cargo"/>
                                <ExcelColumn label="Prenda" value="prenda"/>
                                <ExcelColumn label="Talla" value="talla"/>
                                <ExcelColumn label="Cantidad" value="cantidad"/>
                                <ExcelColumn label="Satelite" value="satelite"/>
                            </ExcelSheet>
                        </ExcelFile>
                        <table className="table">
                            <thead>
                                <tr>
                                    <th>Cargo</th>
                                    <th>Prenda</th>
                                    <th>Talla</th>
                                    <th>Cantidad</th>
                                    <th>Satelite</th>
                                </tr>
                            </thead>
                            <tbody>
                                {this.state.tallasCargos.map(talla => {
                                    return(
                                        <tr>
                                            <td>{talla.cargo}</td>
                                            <td>{talla.prenda}</td>
                                            <td>{talla.talla}</td>
                                            <td>{talla.suma}</td>
                                            <td>{talla.satelite}</td>
                                        </tr>
                                    )
                                })}
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
        );
    }
}

export default Vercargos;