import React, { Component } from 'react';
import ReactExport from "react-export-excel";

const ExcelFile = ReactExport.ExcelFile;
const ExcelSheet = ReactExport.ExcelFile.ExcelSheet;
const ExcelColumn = ReactExport.ExcelFile.ExcelColumn;

class Verempleados extends Component {
    constructor(props){
        super(props)
        this.state = ({
            idCliente: null,
            datas: []
        })
    }

    componentDidMount(){
        var idCliente = sessionStorage.getItem('tablesIdClient')
        this.setState({
            idCliente: idCliente
        })
        this.getDataEmpleados(idCliente)
    }

    getDataEmpleados = id => {
        var data = {
            id: id
        }
        fetch('/getTallasEmpleados', {
            method: 'POST',
            body: JSON.stringify(data),
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            }
        })
            .then(res => res.json())
            .then((data) => {
                this.setState({
                    datas: data
                })
            })
            .then(() => {
                this.getAsistent()
                this.getTallado()
            })
            .catch(err => console.error(err))
    }
    render() {
        return (
            <div>
               <div className="row mt-3">
                    <div className="col-12">
                        <ExcelFile element={<button>Descargar Excel</button>}>
                            <ExcelSheet data={this.state.datas} name="Satelites">
                                <ExcelColumn label="Cargo" value="cargo"/>
                                <ExcelColumn label="Empleado" value="empleado"/>
                                <ExcelColumn label="Cedula" value="cedula"/>
                                <ExcelColumn label="Prenda" value="prenda"/>
                                <ExcelColumn label="Talla" value="talla"/>
                                <ExcelColumn label="Largo" value="largo"/>
                                <ExcelColumn label="Especiales" value="especiales"/>
                                <ExcelColumn label="Observaciones" value="observaciones"/>
                                <ExcelColumn label="Cantidad" value="cantidad"/>
                                <ExcelColumn label="Satelite" value="satelite"/>
                            </ExcelSheet>
                        </ExcelFile>
                        <table className="table">
                            <thead>
                                <tr>
                                    <th>Cargo</th>
                                    <th>Empleado</th>
                                    <th>Cedula</th>
                                    <th>Prenda</th>
                                    <th>Talla</th>
                                    <th>Largo</th>
                                    <th>Especiales</th>
                                    <th>Observaciones</th>
                                    <th>Cantidad</th>
                                    <th>Satelite</th>
                                </tr>
                            </thead>
                            <tbody>
                                {this.state.datas.map(data => {
                                    return(
                                        <tr>
                                            <td>{data.cargo}</td>
                                            <td>{data.empleado}</td>
                                            <td>{data.cedula}</td>
                                            <td>{data.prenda}</td>
                                            <td>{data.talla}</td>
                                            <td>{data.largo}</td>
                                            <td>{data.especiales}</td>
                                            <td>{data.observaciones}</td>
                                            <td>{data.cantidad}</td>
                                            <td>{data.satelite}</td>
                                        </tr>
                                    )
                                })}
                            </tbody>
                        </table>
                    </div>
                </div> 
            </div>
        );
    }
}

export default Verempleados;