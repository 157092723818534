import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import './LoadClient.css';
import Select from 'react-select';

class LoadClient extends Component {
    constructor(props){
        super(props)
        this.state = ({
            idCliente: '',
            clientes: [],
            selectClientes: [],
            cliente: null,
            asistente: '',
            asistentes: [],
            tallajes: []
        })
    }
    componentDidMount(){
        this.getClient()
        this.getAsistente()
        this.getTallaje()
    }
    getClient = () => {
        var datos = {
            dato: 'hola'
        }
        fetch('/getClient', {
            method: 'POST',
            body: JSON.stringify(datos),
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            }
        })
            .then(res => res.json())
            .then((data) => {
                this.setState({
                    clientes: data
                })
            })
            .then(() => {
                this.selectClient()
            })
            .catch(err => console.error(err))
    }
    selectClient = () => {
        var array = []
        this.state.clientes.map(cli => {
            array.push({
                value: cli.id_cliente,
                label: cli.cliente
            })
        })
        this.setState({
            selectClientes: array
        })
    }
    getAsistente(){
        var datos = {
            idCliente: 'hola'
        }
        fetch('/getAsistente', {
            method: 'POST',
            body: JSON.stringify(datos),
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            }
        })
            .then(res => res.json())
            .then((data) => {
                this.setState({
                    asistentes: data
                })
            })
            .catch(err => console.error(err))
    }
    getTallaje(){
        var datos = {
            idCliente: 'hola'
        }
        fetch('/getTallaje', {
            method: 'POST',
            body: JSON.stringify(datos),
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            }
        })
            .then(res => res.json())
            .then((data) => {
                this.setState({
                    tallajes: data
                })
            })
            .catch(err => console.error(err))
    }
    sendTallaje = () => {
        var {cliente, asistente} = this.state
        var datos = {
            asistente: asistente,
            cliente: cliente
        }
        fetch('/sendTallaje', {
            method: 'POST',
            body: JSON.stringify(datos),
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            }
        })
            .then(res => res.json())
            .then((data) => {
                if(data.resp === 'ok'){
                    alert('Enviado a tallaje')
                    this.props.history.push("/");
                }
            })
            .catch(err => console.error(err))
    }
    enviar = e => {
        const data = new FormData(e.target)

        fetch('/saveFileTallaje', {
            method: 'POST',
            body: data
        })
            .then(res => res.json())
            .then((data) => {
                if(data.respuesta === 'ok'){
                    alert('Enviado correctamente a tallaje')
                    this.getTallaje()
                }
            })
            .catch(err => console.error(err))
    }
    deleteTallaje = data => {
        var datos = {
            id: data
        }
        fetch('/deleteTallaje', {
            method: 'POST',
            body: JSON.stringify(datos),
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            }
        })
            .then(res => res.json())
            .then((data) => {
                if(data.resp === 'ok'){
                    this.getTallaje()
                }
            })
            .catch(err => console.error(err))
    }
    render() {
        return (
            <div className="container recuadro">
                <div className="row">
                    <div className="col-12 text-right backLink">
                        <a href="#!" onClick={() => {
                            this.getClient()
                            this.getAsistente()
                        }}><i className="fas fa-sync"></i></a>
                    </div>
                </div>
                <div className="row">
                    <div className="col-12 text-center mt-4">
                        <h3>Asigna un asistente de tallaje al cliente y adjunta la ficha técnica</h3>
                    </div>
                </div>
                <div className="row">
                    <div className="col-md-6 offset-md-3 text-center">
                        <form method="post" action="/saveFileTallaje" enctype="multipart/form-data" onSubmit={(e) => {this.enviar(e); e.preventDefault()}}>
                            <div className="form-group">
                                <label htmlFor="cliente">Cliente</label>
                                <Select
                                    options={this.state.selectClientes}
                                    value={this.state.cliente}
                                    onChange={(value) => {
                                        this.setState({cliente: value, idCliente: value.value})
                                    }}
                                    placeholder='Cliente'
                                    name="cliente"
                                />
                            </div>
                            <div className="form-group mt-3">
                                <label htmlFor="asistente">Asistente de Tallaje</label>
                                <select
                                    className="form-control"
                                    id="asistente"
                                    value={this.state.asistente}
                                    onChange={e => this.setState({
                                        asistente: e.target.value
                                    })}
                                    name="asistente"
                                >
                                    <option value=""></option>
                                    {this.state.asistentes.map( asist => {
                                        return(
                                            <option key={asist.id_asistente} value={asist.id_asistente}>{asist.asistente}</option>
                                        )
                                    })}
                                </select>
                            </div>
                            <input type="file" name="file" className="form-control mt-3"/><br/>
                            <input type="submit" value="Agregar" className="btn btn-dark"/>
                        </form>
                    </div>
                </div>
                <div className="row mb-r">
                    <div className="col-12 text-center">
                        <table className="table">
                            <thead>
                                <tr>
                                    <th>Cliente</th>
                                    <th>Asistente</th>
                                    <th>Ficha tecnica</th>
                                    <th></th>
                                </tr>
                            </thead>
                            <tbody>
                                {this.state.tallajes.map((tallaje, index) => {
                                    return(
                                        <tr>
                                            <td>{tallaje.cliente}</td>
                                            <td>{tallaje.asistente}</td>
                                            <td><a href={'files/'+tallaje.ficha}>Ficha Tecnica</a></td>
                                            <td><button onClick={() => this.deleteTallaje(tallaje.idtallaje)}><i className="fas fa-trash text-danger"></i></button></td>
                                        </tr>
                                    )
                                })}
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
        );
    }
}

export default LoadClient;