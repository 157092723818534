import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import Select from 'react-select';

class Cargos extends Component {
    constructor(props){
        super(props)
        this.state = ({
            cargo: '',
            cargos: [],
            clientes: [],
            selectClientes: [],
            idClient: '',
            cliente: null
        })
    }
    componentDidMount(){
        this.getClient()
    }
    getClient = () => {
        var datos = {
            dato: 'hola'
        }
        fetch('/getClient', {
            method: 'POST',
            body: JSON.stringify(datos),
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            }
        })
            .then(res => res.json())
            .then((data) => {
                this.setState({
                    clientes: data
                })
            })
            .then(() => {
                this.selectClient()
            })
            .catch(err => console.error(err))
    }
    selectClient = () => {
        var array = []
        this.state.clientes.map(cli => {
            array.push({
                value: cli.id_cliente,
                label: cli.cliente
            })
        })
        this.setState({
            selectClientes: array
        })
    }
    getCargos(){
        var datos = {
            idCliente: this.state.idClient
        }
        fetch('/getCargo', {
            method: 'POST',
            body: JSON.stringify(datos),
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            }
        })
            .then(res => res.json())
            .then((data) => {
                this.setState({
                    cargos: data
                })
            })
            .catch(err => console.error(err))
    }
    addCargo = () => {
        var {cargo, idClient} = this.state
        var datos = {
            cargo: cargo,
            idCliente: idClient
        }
        fetch('/addCargo', {
            method: 'POST',
            body: JSON.stringify(datos),
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            }
        })
            .then(res => res.json())
            .then((data) => {
                if(data.resp === 'ok'){
                    this.getCargos()
                    this.setState({cargo: ''})
                }
            })
            .catch(err => console.error(err))
    }
    deleteCargo(idCargo){
        var datos = {
            idCargo: idCargo
        }
        fetch('/deleteCargo', {
            method: 'POST',
            body: JSON.stringify(datos),
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            }
        })
            .then(res => res.json())
            .then((data) => {
                if(data.resp === 'ok'){
                    this.getCargos()
                }
            })
            .catch(err => console.error(err))
    }
    render() {
        return (
            <div className="container recuadro">
                <div className="row">
                    <div className="col-12 text-right backLink">
                        <a href="#!" onClick={() => this.getClient()}><i className="fas fa-sync"></i></a>
                    </div>
                </div>
                <div className="row">
                    <div className="col-12 text-center">
                        <h3>Agrega Cargos</h3>
                    </div>
                </div>
                <div className="row">
                    <div className="col-md-6 offset-md-3 text-center">
                        <div className="form-group">
                            <label htmlFor="cliente">Selecciona Cliente</label>
                            <Select
                                options={this.state.selectClientes}
                                value={this.state.cliente}
                                onChange={(value) => {
                                    this.setState({cliente: value, idClient: value.value})
                                }}
                                placeholder='Cliente'
                            />
                            <button className="btn btn-dark mt-2" onClick={() => this.getCargos()}>Seleccionar</button>
                        </div>
                    </div>
                </div>
                <div className="row">
                    <div className="col-md-6 offset-md-3 form-group text-center">
                        <input 
                            type="text" 
                            className="form-control" placeholder="Cargo" 
                            id="sector"
                            value={this.state.cargo}
                            onChange={e => this.setState({cargo: e.target.value})}
                            required
                        />
                    </div>
                </div>
                <div className="row">
                    <div className="col-12 text-center">
                        <button className="btn btn-outline-dark" onClick={() => this.addCargo()}>Agregar</button>
                    </div>
                </div>
                <div className="row mt-3">
                    <div className="col-12">
                        <table className="table">
                            <thead>
                                <tr>
                                    <th>Cargo</th>
                                    <th>Eliminar</th>
                                </tr>
                            </thead>
                            <tbody>
                                {this.state.cargos.map(carg => {
                                    return(
                                        <tr key={carg.id_cargo}>
                                            <td>{carg.cargo}</td>
                                            <td><button onClick={() => this.deleteCargo(carg.id_cargo)}><i className="fas fa-trash text-danger"></i></button></td>
                                        </tr>
                                    )
                                })}
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
        );
    }
}

export default Cargos;