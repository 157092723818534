import React, { Component } from 'react';
import ReactExport from "react-export-excel";

const ExcelFile = ReactExport.ExcelFile;
const ExcelSheet = ReactExport.ExcelFile.ExcelSheet;
const ExcelColumn = ReactExport.ExcelFile.ExcelColumn;

class Verdatos extends Component {
    constructor(props){
        super(props)
        this.state = ({
            idCliente: null,
            tallados: []
        })
    }

    componentDidMount(){
        var idCliente = sessionStorage.getItem('tablesIdClient')
        this.setState({
            idCliente: idCliente
        })
        this.getTallado(idCliente)
    }
    getTallado = (idCliente) => {
        var data = {
            id: idCliente
        }
        fetch('/getTallado', {
            method: 'POST',
            body: JSON.stringify(data),
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            }
        })
            .then(res => res.json())
            .then((data) => {
                this.setState({
                    tallados: data
                })
            })
            .catch(err => console.error(err))
    }
    render() {
        return (
            <div>
                <div className="row">
                    <div className="col-12 mt-5">
                        <h4>Datos firma y foto de empleados</h4>
                        <ExcelFile element={<button>Descargar Excel</button>}>
                            <ExcelSheet data={this.state.tallados} name="Satelites">
                                <ExcelColumn label="Cargo" value="cargo"/>
                                <ExcelColumn label="Empleado" value="empleado"/>
                                <ExcelColumn label="Cedula" value="cedula"/>
                                <ExcelColumn label="Genero" value="genero"/>
                            </ExcelSheet>
                        </ExcelFile>
                        <table className="table">
                            <thead>
                                <tr>
                                    <th>Empleado</th>
                                    <th>Cédula</th>
                                    <th>Género</th>
                                    <th>Cargo</th>
                                    <th>Firma</th>
                                    <th>Foto</th>
                                </tr>
                            </thead>
                            <tbody>
                                {this.state.tallados.map(tall => {
                                    return(
                                        <tr>
                                            <td>{tall.empleado}</td>
                                            <td>{tall.cedula}</td>
                                            <td>{tall.genero}</td>
                                            <td>{tall.cargo}</td>
                                            <td><img src={tall.firma} alt="foto" width="60" /></td>
                                            <td><a href={'images/'+tall.foto} target="_blank">Foto</a></td>
                                        </tr>
                                    )
                                })}
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
        );
    }
}

export default Verdatos;