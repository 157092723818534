import React, { Component } from 'react';
import { Link } from 'react-router-dom';

class AddTallaje extends Component {
    constructor(props){
        super(props)
        this.state = ({
            asistentes: [],
            asistente: '',
            password: ''
        })
    }
    componentDidMount(){
        this.getAsistente()
    }
    getAsistente(){
        var datos = {
            idCliente: 'hola'
        }
        fetch('/getAsistente', {
            method: 'POST',
            body: JSON.stringify(datos),
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            }
        })
            .then(res => res.json())
            .then((data) => {
                this.setState({
                    asistentes: data
                })
            })
            .catch(err => console.error(err))
    }
    addAsistente(){
        var datos = {
            asistente: this.state.asistente,
            pass: this.state.password
        }
        fetch('/addAsistente', {
            method: 'POST',
            body: JSON.stringify(datos),
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            }
        })
            .then(res => res.json())
            .then((data) => {
                if(data.resp === 'ok'){
                    this.getAsistente()
                }
            })
            .catch(err => console.error(err))
    }
    deleteAsistente(datos){
        var data = {
            idAsistente: datos
        }
        fetch('/deleteAsistente', {
            method: 'POST',
            body: JSON.stringify(data),
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            }
        })
            .then(res => res.json())
            .then((data) => {
                if(data.resp === 'ok'){
                    this.getAsistente()
                }
            })
            .catch(err => console.error(err))
    }
    render() {
        return (
            <div className="container recuadro">
                <div className="row">
                    <div className="col-12 text-center mt-4">
                        <h3>Agrega Asistentes de Tallaje</h3>
                    </div>
                </div>
                <div className="row">
                    <div className="col-md-4 offset-md-2 form-group text-center">
                        <label htmlFor="asistente">Asistente Tallaje</label>
                        <input 
                            type="text" 
                            className="form-control" placeholder="Asistente" 
                            id="asistente"
                            value={this.state.asistente}
                            onChange={e => this.setState({asistente: e.target.value})}
                            required
                        />
                    </div>
                    <div className="col-md-4 form-group text-center">
                        <label htmlFor="password">Contraseña</label>
                        <input 
                            type="text" 
                            className="form-control" placeholder="Contraseña" 
                            id="password"
                            value={this.state.password}
                            onChange={e => this.setState({password: e.target.value})}
                            required
                        />
                    </div>
                </div>
                <div className="row">
                    <div className="col-12 text-center">
                        <button className="btn btn-outline-dark" onClick={() => this.addAsistente()}>Agregar</button>
                    </div>
                </div>
                <div className="row mt-3">
                    <div className="col-12">
                        <table className="table">
                            <thead>
                                <tr>
                                    <th>Asistente</th>
                                    <th>Contraseña</th>
                                    <th>Eliminar</th>
                                </tr>
                            </thead>
                            <tbody>
                                {this.state.asistentes.map(asist => {
                                    return(
                                        <tr key={asist.id_asistente}>
                                            <td>{asist.asistente}</td>
                                            <td>{asist.pass}</td>
                                            <td><button onClick={() => this.deleteAsistente(asist.id_asistente)}><i className="fas fa-trash text-danger"></i></button></td>
                                        </tr>
                                    )
                                })}
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
        );
    }
}

export default AddTallaje;