import React, { Component } from 'react';
import './Armado.css';
import { Link } from 'react-router-dom';
import Select from 'react-select';

class Cargos extends Component {
    constructor(props){
        super(props)
        this.state={
            cargo: '',
            armado: [],
            prend: '',
            prendaLabel: null,
            satelit: '',
            cantidad: 1,
            idClient: 0,
            clientes: [],
            cliente: null,
            selectClientes: [],
            cargos: [],
            prendas: [],
            prendasSelect: [],
            satelites: [],
            cargoFinal: []
        }
    }
    componentDidMount(){
        this.getPrendas()
        this.getSatelite()
        this.getClient()
    }
    getClient = () => {
        var datos = {
            dato: 'hola'
        }
        fetch('/getClient', {
            method: 'POST',
            body: JSON.stringify(datos),
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            }
        })
            .then(res => res.json())
            .then((data) => {
                this.setState({
                    clientes: data
                })
            })
            .then(() => {
                this.selectClient()
            })
            .catch(err => console.error(err))
    }
    selectClient = () => {
        var array = []
        this.state.clientes.map(cli => {
            array.push({
                value: cli.id_cliente,
                label: cli.cliente
            })
        })
        this.setState({
            selectClientes: array
        })
    }
    getPrendas(){
        var data = {
            data: 'get',
        }
        fetch('/getPrendas', {
            method: 'POST',
            body: JSON.stringify(data),
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            }
        })
            .then(res => res.json())
            .then((data) => {
                this.setState({
                    prendas: data
                })
            })
            .then(() => this.selectPrendas())
            .catch(err => console.error(err))
    }
    getSatelite(){
        var data = {
            data: 'get',
        }
        fetch('/getSatelite', {
            method: 'POST',
            body: JSON.stringify(data),
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            }
        })
            .then(res => res.json())
            .then((data) => {
                this.setState({
                    satelites: data
                })
            })
            .catch(err => console.error(err))
    }
    getCargos(){
        var datos = {
            idCliente: this.state.idClient
        }
        fetch('/getCargo', {
            method: 'POST',
            body: JSON.stringify(datos),
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            }
        })
            .then(res => res.json())
            .then((data) => {
                this.setState({
                    cargos: data
                })
            })
            .catch(err => console.error(err))
    }
    getArmado(){
        var datos = {
            idCliente: this.state.idClient
        }
        fetch('/getArmado', {
            method: 'POST',
            body: JSON.stringify(datos),
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            }
        })
            .then(res => res.json())
            .then((data) => {
                this.setState({
                    armado: data
                })
            })
            .catch(err => console.error(err))
    }
    addArmado(){
        var {idClient, cargo, prend, satelit, cantidad} = this.state
        var datos = {
            idCliente: idClient,
            cargo: cargo,
            prenda: prend,
            satelite: satelit,
            cantidad: cantidad
        }
        fetch('/addArmado', {
            method: 'POST',
            body: JSON.stringify(datos),
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            }
        })
            .then(res => res.json())
            .then((data) => {
                if(data.resp === 'ok'){
                    this.getArmado()
                }
                this.setState({cantidad: 1})
            })
            .catch(err => console.error(err))
    }
    deleteArmado(idArmado){
        var datos = {
            idArmado: idArmado
        }
        fetch('/deleteArmado', {
            method: 'POST',
            body: JSON.stringify(datos),
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            }
        })
            .then(res => res.json())
            .then((data) => {
                if(data.resp === 'ok'){
                    this.getArmado()
                }
            })
            .catch(err => console.error(err))
    }
    selectCargo = () => {
        this.getCargos()
        this.getArmado()
    }
    update = () => {
        this.getPrendas()
        this.getSatelite()
        this.getClient()
    }
    selectPrendas = () => {
        var array = []
        this.state.prendas.map(pre => {
            array.push({
                value: pre.id_prenda,
                label: pre.prenda
            })
        })
        this.setState({
            prendasSelect: array
        })
    }
    setPrenda = (data) => {
        this.setState({
            prendaLabel: data.label
        })
        console.log(data)
    }
    render() {
        return (
            <div className="container recuadro">
                <div className="row">
                    <div className="col-12 text-right backLink">
                        <a href="#!" onClick={() => this.update()}><i className="fas fa-sync"></i></a>
                    </div>
                </div>
                <div className="row">
                    <div className="col-12 text-center">
                        <h3>Arma Cargo, Prenda y Satelite</h3>
                    </div>
                </div>
                <div className="row">
                    <div className="col-md-6 offset-md-3 text-center">
                        <div className="form-group">
                            <label htmlFor="cliente">Selecciona Cliente</label>
                            <Select
                                options={this.state.selectClientes}
                                value={this.state.cliente}
                                onChange={(value) => {
                                    this.setState({cliente: value, idClient: value.value})
                                }}
                                placeholder='Cliente'
                            />
                            <button className="btn btn-dark mt-2" onClick={() => this.selectCargo()}>Seleccionar</button>
                        </div>
                    </div>
                </div>
                <div className="row">
                    <div className="form-group col-md-3">
                        <p><strong>Selecciona un Cargo (paso1)</strong></p>
                        {this.state.cargos.map(cargo => {
                            return(
                                <div className="form-check form-check-inline" key={cargo.id_cargo}>
                                    <label className="form-check-label">
                                        <input className="form-check-input" type="radio" name="cargos" value={cargo.id_cargo}
                                        onChange={e => this.setState({cargo: e.target.value})}
                                        />
                                        {cargo.cargo}
                                    </label>
                                </div> 
                            )
                        })}
                    </div>
                    <div className="form-group col-md-3">
                        <p><strong>Cuantas prendas? (paso2)</strong></p>
                        <input type="number" className="form-control" value={this.state.cantidad} onChange={e => this.setState({cantidad: e.target.value})}/>
                    </div>
                    <div className="form-group col-md-3">
                        <p><strong>Selecciona una prenda (paso3)</strong></p>
                        <Select
                            options={this.state.prendasSelect}
                            value={this.state.prendaLabel}
                            onChange={(value) => {
                                this.setState({prendaLabel: value, prend: value.value})
                            }}
                            placeholder='Prenda'
                        />
                    </div>
                    <div className="form-group col-md-3">
                        <p><strong>Selecciona un satelite (paso4)</strong></p>
                        {this.state.satelites.map(satelite => {
                            return(
                                <div className="form-check form-check-inline" key={satelite.id_satelite}>
                                    <label className="form-check-label">
                                        <input className="form-check-input" type="radio" name="satelite" value={satelite.id_satelite}
                                        onChange={e => this.setState({satelit: e.target.value})}
                                        />
                                        {satelite.satelite}
                                    </label>
                                </div> 
                            )
                        })}
                    </div>
                </div>
                <div className="row">
                    <div className="col-12 text-center">
                        <button className="btn btn-outline-dark" onClick={() => this.addArmado()}>Agregar</button>
                    </div>
                </div>
                <div className="row mt-3">
                    <div className="col-12">
                        <table className="table">
                            <thead>
                                <tr>
                                    <th>Cargo</th>
                                    <th>Prenda</th>
                                    <th>Cant.</th>
                                    <th>Satelite</th>
                                    <th>Eliminar</th>
                                </tr>
                            </thead>
                            <tbody>
                                {this.state.armado.map(build => {
                                    return(
                                        <tr key={build.id_armado}>
                                            <td>{build.cargo}</td>
                                            <td>{build.prenda}</td>
                                            <td>{build.cantidad}</td>
                                            <td>{build.satelite}</td>
                                            <td><button onClick={() => this.deleteArmado(build.id_armado)}><i className="fas fa-trash text-danger"></i></button></td>
                                        </tr>
                                    )
                                })}
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
        );
    }
}

export default Cargos;