import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import './BuildClient.css';
import { loadavg } from 'os';

class BuildClient extends Component {
    constructor(props){
        super(props)
        this.state = {
            id: '',
            name: '',
            phone: '',
            nit: '',
            direction: '',
            sector: '',
            contact: '',
            city: '',
            clientes: [],
            edit: false
        }
    }
    componentDidMount(){
        this.getClient()
    }
    getClient = () => {
        var datos = {
            dato: 'hola'
        }
        fetch('/getClient', {
            method: 'POST',
            body: JSON.stringify(datos),
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            }
        })
            .then(res => res.json())
            .then((data) => {
                this.setState({
                    clientes: data
                })
            })
            .catch(err => console.error(err))
    }
    SendForm = (e) => {
        e.preventDefault()
        var {name, phone, nit, direction, sector, contact, city} = this.state
        var form = {
            name: name,
            phone: phone,
            nit: nit,
            direction: direction,
            sector: sector,
            contact: contact,
            city: city
        }
        fetch('/addClient', {
            method: 'POST',
            body: JSON.stringify(form),
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            }
        })
            .then(res => res.json())
            .then((data) => {
                if(data.id === 'existe'){
                    alert('Este cliente ya existe')
                }else{
                    this.getClient()
                    this.setState({
                        id: '',
                        name: '',
                        phone: '',
                        nit: '',
                        direction: '',
                        sector: '',
                        contact: '',
                        city: '',
                        edit: false
                    })
                }
            })
            .catch(err => console.error(err))
    }
    deleteClient = (data) => {
        var a = window.confirm('Desea borrar este cliente?')
        if(a){
            fetch('/deleteClient', {
                method: 'POST',
                body: JSON.stringify({idClient: data}),
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json'
                }
            })
                .then(res => res.json())
                .then((data) => {
                    if(data.respuesta === 'ok'){
                        this.getClient()
                    }
                })
                .catch(err => console.error(err))
        }
    }
    editClient = (id, cliente, telefono, nit, direccion, sector, contacto, ciudad) => {
        this.setState({
            id: id,
            name: cliente,
            phone: telefono,
            nit: nit,
            direction: direccion,
            sector: sector,
            contact: contacto,
            city: ciudad,
            edit: true
        })
    }
    cancelEdit = () => {
        this.setState({
            id: '',
            name: '',
            phone: '',
            nit: '',
            direction: '',
            sector: '',
            contact: '',
            city: '',
            edit: false
        })
    }
    editarCliente = () => {
        var {id, name, phone, nit, direction, sector, contact, city} = this.state
        var form = {
            id: id,
            name: name,
            phone: phone,
            nit: nit,
            direction: direction,
            sector: sector,
            contact: contact,
            city: city
        }
        fetch('/editClient', {
            method: 'POST',
            body: JSON.stringify(form),
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            }
        })
            .then(res => res.json())
            .then((data) => {
                this.getClient()
                this.setState({
                    id: '',
                    name: '',
                    phone: '',
                    nit: '',
                    direction: '',
                    sector: '',
                    contact: '',
                    city: '',
                    edit: false
                })
            })
            .catch(err => console.error(err))
    }
    render() {
        return (
            <div className="container recuadro">
                <form method="POST" onSubmit={e => this.SendForm(e, this.props)}>
                    <div className="row pt-4">
                        <div className="col-12 text-center">
                            <h3>Agrega nuevo cliente</h3>
                        </div>
                    </div>
                    <div className="row mt-3">
                        <div className="form-group col-md-6">
                            <label htmlFor="nombre">Nombre</label>
                            <input 
                                type="text" 
                                className="form-control" placeholder="Nombre" 
                                id="nombre"
                                value={this.state.name}
                                onChange={e => this.setState({name: e.target.value})}
                                required
                            />
                        </div>
                        <div className="form-group col-md-6">
                            <label htmlFor="telefono">Teléfono</label>
                            <input 
                                type="text" 
                                className="form-control" placeholder="Teléfono" 
                                id="telefono"
                                value={this.state.phone}
                                onChange={e => this.setState({phone: e.target.value})}
                                required
                            />
                        </div>
                        <div className="form-group col-md-6">
                            <label htmlFor="nit">Nit</label>
                            <input 
                                type="text" 
                                className="form-control" placeholder="Nit" 
                                id="nit"
                                value={this.state.nit}
                                onChange={e => this.setState({nit: e.target.value})}
                                required
                            />
                        </div>
                        <div className="form-group col-md-6">
                            <label htmlFor="direccion">Dirección</label>
                            <input 
                                type="text" 
                                className="form-control" placeholder="Dirección" 
                                id="direccion"
                                value={this.state.direction}
                                onChange={e => this.setState({direction: e.target.value})}
                                required
                            />
                        </div>
                        <div className="form-group col-md-6">
                            <label htmlFor="sector">Sector</label>
                            <input 
                                type="text" 
                                className="form-control" placeholder="Sector" 
                                id="sector"
                                value={this.state.sector}
                                onChange={e => this.setState({sector: e.target.value})}
                                required
                            />
                        </div>
                        <div className="form-group col-md-6">
                            <label htmlFor="contacto">Contacto</label>
                            <input 
                                type="text" 
                                className="form-control" placeholder="Contacto" 
                                id="contacto"
                                value={this.state.contact}
                                onChange={e => this.setState({contact: e.target.value})}
                                required
                            />
                        </div>
                        <div className="form-group col-md-6">
                            <label htmlFor="ciudad">Ciudad</label>
                            <input 
                                type="text" 
                                className="form-control" placeholder="Ciudad" 
                                id="ciudad"
                                value={this.state.city}
                                onChange={e => this.setState({city: e.target.value})}
                                required
                            />
                        </div>
                        <div className="col-md-6 mt-4 text-right">
                            {!this.state.edit ?
                            <button type="submit" className="btn btn-outline-primary">Añadir Cliente</button> :
                            <div><button type="button" className="btn btn-outline-dark" onClick={() => this.cancelEdit()}>Cancelar</button>
                            <button type="button" className="btn btn-outline-warning" onClick={() => this.editarCliente()}>Modificar Cliente</button></div>}
                        </div>
                    </div>
                </form>
                <div className="row">
                    <div className="col-12 mt-4">
                        <table className="table">
                            <thead>
                                <tr>
                                    <th>Nombre</th>
                                    <th>Teléfono</th>
                                    <th>Nit</th>
                                    <th>Dirección</th>
                                    <th>Sector</th>
                                    <th>Contácto</th>
                                    <th>Ciudad</th>
                                    <th></th>
                                    <th></th>
                                </tr>
                            </thead>
                            <tbody>
                                {this.state.clientes.map((client, index) => {
                                    return(
                                        <tr>
                                            <td>{client.cliente}</td>
                                            <td>{client.telefono}</td>
                                            <td>{client.nit}</td>
                                            <td>{client.direccion}</td>
                                            <td>{client.sector}</td>
                                            <td>{client.contacto}</td>
                                            <td>{client.ciudad}</td>
                                            <td><a href="#!" onClick={() => this.editClient(client.id_cliente, client.cliente, client.telefono, client.nit, client.direccion, client.sector, client.contacto, client.ciudad)}><i className="fas fa-pencil-alt"></i></a></td>
                                            <td><a href="#!" onClick={() => this.deleteClient(client.id_cliente)}><i className="fas fa-trash-alt"></i></a></td>
                                        </tr>
                                    )
                                })}
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
        );
    }
}

export default BuildClient;