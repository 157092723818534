import React, { Component } from 'react';
import Select from 'react-select';
import './Produccion.css'
import Versatelites from './Versatelites'
import Vercargos from './Vercargos'
import Verempleados from './Verempleados'
import Verdatos from './Verdatos'

class Produccion extends Component {
    constructor(props){
        super(props)
        this.state = ({
            clientes: [],
            selectClientes: [],
            cliente: null,
            idCliente: null,
            asistentes: [],
            tallados: [],
            btnVerPor: null
        })
    }
    componentDidMount(){
        this.getClient()
    }
    getClient = () => {
        var datos = {
            dato: 'hola'
        }
        fetch('/getClient', {
            method: 'POST',
            body: JSON.stringify(datos),
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            }
        })
            .then(res => res.json())
            .then((data) => {
                this.setState({
                    clientes: data
                })
            })
            .then(() => {
                this.selectClient()
            })
            .catch(err => console.error(err))
    }
    getAsistent = () => {
        var datos = {
            id: this.state.idCliente
        }
        fetch('/getAsistent', {
            method: 'POST',
            body: JSON.stringify(datos),
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            }
        })
            .then(res => res.json())
            .then((data) => {
                this.setState({
                    asistentes: data
                })
            })
            .catch(err => console.error(err))
    }
    selectClient = () => {
        var array = []
        this.state.clientes.map(cli => {
            array.push({
                value: cli.id_cliente,
                label: cli.cliente
            })
        })
        this.setState({
            selectClientes: array
        })
    }
    btnSelectCliente = () => {
        var data = {
            id: this.state.idCliente
        }
        sessionStorage.setItem('tablesIdClient', this.state.idCliente)
        this.getAsistent()
    }

    selectVerPor = (data) => {
        if(sessionStorage.getItem('tablesIdClient')){
            this.setState({
                btnVerPor: data
            })
        }else{
            alert('Seleccione un cliente')
        }
    }
    
    render() {
        return (
            <div className="container recuadro">
                <div className="row">
                    <div className="col-12 text-right backLink">
                        <a href="#!" onClick={() => this.getClient()}><i className="fas fa-sync"></i></a>
                    </div>
                </div>
                <div className="row">
                    <div className="col-12 text-center mt-4">
                        <h3>Producción</h3>
                    </div>
                </div>
                <div className="row">
                    <div className="col-12 text-center mb-5 mt-5">
                        <h5>Asistentes de tallaje:</h5>
                        {this.state.asistentes.map(asist => {
                            return(
                                <div key={asist.idTallaje}>
                                    <h6>{asist.asistente}</h6>
                                    <p>Descargar Ficha Tecnica:</p>
                                    <a href={'files/'+asist.ficha} target="_blanck"><i className="far fa-file-pdf"></i></a>
                                </div>
                            )
                        })}
                    </div>
                </div>
                <div className="row">
                    <div className="col-md-6 offset-md-3 text-center">
                        <div className="form-group">
                            <label htmlFor="cliente">Selecciona Cliente</label>
                            <Select
                                options={this.state.selectClientes}
                                value={this.state.cliente}
                                onChange={(value) => {
                                    this.setState({cliente: value, idCliente: value.value})
                                }}
                                placeholder='Cliente'
                            />
                            <button className="btn btn-dark mt-2" onClick={() => this.btnSelectCliente()}>Seleccionar</button>
                        </div>
                    </div>
                </div>
                <div className="row">
                    <div className="col-12 text-center mb-5">
                        <div className="btn-group" role="group">
                            <button type="button" className="btn btn-info" onClick={() => this.selectVerPor(<Versatelites/>)}>Ver por Satelite</button>
                            <button type="button" className="btn btn-info" onClick={() => this.selectVerPor(<Vercargos/>)}>Ver por cargos, tallas y satelites</button>
                            <button type="button" className="btn btn-info" onClick={() => this.selectVerPor(<Verempleados/>)}>Ver por Empleado</button>
                            <button type="button" className="btn btn-info" onClick={() => this.selectVerPor(<Verdatos/>)}>Ver firma, datos y fotos empleados</button>
                        </div>
                    </div>
                </div>
                {this.state.btnVerPor}
                {/* <div className="row">
                    <div className="col-12">
                        <h4>Consolidado por cargos, tallas y satelites</h4>
                        <table className="table">
                            <thead>
                                <tr>
                                    <th>Cargo</th>
                                    <th>Prenda</th>
                                    <th>Talla</th>
                                    <th>Cantidad</th>
                                    <th>Satelite</th>
                                </tr>
                            </thead>
                            <tbody>
                                {this.state.tallasCargos.map(talla => {
                                    return(
                                        <tr>
                                            <td>{talla.cargo}</td>
                                            <td>{talla.prenda}</td>
                                            <td>{talla.talla}</td>
                                            <td>{talla.suma}</td>
                                            <td>{talla.satelite}</td>
                                        </tr>
                                    )
                                })}
                            </tbody>
                        </table>
                    </div>
                </div>

                <div className="row">
                    <div className="col-12 mt-5">
                        <h4>Datos firma y foto de empleados</h4>
                        <table className="table">
                            <thead>
                                <tr>
                                    <th>Empleado</th>
                                    <th>Cédula</th>
                                    <th>Género</th>
                                    <th>Cargo</th>
                                    <th>Firma</th>
                                    <th>Foto</th>
                                </tr>
                            </thead>
                            <tbody>
                                {this.state.tallados.map(tall => {
                                    return(
                                        <tr>
                                            <td>{tall.empleado}</td>
                                            <td>{tall.cedula}</td>
                                            <td>{tall.genero}</td>
                                            <td>{tall.cargo}</td>
                                            <td><img src={tall.firma} alt="foto" width="60" /></td>
                                            <td>{tall.foto}</td>
                                        </tr>
                                    )
                                })}
                            </tbody>
                        </table>
                    </div>
                </div> */}
            </div>
        );
    }
}

export default Produccion;