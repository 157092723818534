import React, { Component } from 'react';
import { Link } from 'react-router-dom';

class Nav extends Component {
    cerrarSesion(){
        localStorage.clear()
    }
    render() {
        return (
            <div>
                <nav className="navbar navbar-dark  bg-dark">
                <Link className="navbar-brand" to="/"><img className="logo" src="img/logo.png" alt="logo"/></Link>
                    <div className="" id="navbarSupportedContent">
                        <ul className="navbar-nav ml-auto">
                            <li className="nav-item active">
                                <Link className="nav-link" to="/home">{this.props.site}</Link>
                            </li>
                            {/* <li className="nav-item">
                                <a href="/" className="nav-link" onClick={() => this.cerrarSesion()}><i class="far fa-times-circle"></i></a>
                            </li> */}
                        </ul>
                    </div>
                </nav>
            </div>
        );
    }
}

export default Nav;