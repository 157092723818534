import React, { Component } from 'react';
import {Redirect} from 'react-router-dom';

class Login extends Component {
    constructor(props){
        super(props)
        this.state = {
            name: '',
            pass: '',
            authen: false
        }
        this.handleSubmit = this.handleSubmit.bind(this)
    }

    handleSubmit(e) {
        e.preventDefault()
        const nodeLogin = this.props.nodeLogin
        const dataLogin = {
            user: this.state.name,
            pass: this.state.pass
        }
        fetch('/login', {
            method: 'POST',
            body: JSON.stringify(dataLogin),
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            }
        })
            .then(res => res.json())
            .then((data) => {
                if(data.token.length > 10){
                    localStorage.setItem('token', data.token)
                }
                this.setState({ authen: true})
            })
            .catch(err => console.error(err))
    }

    isAuth(){
        const token = localStorage.getItem('token')
        return token && token.length > 10
    }
    
    render() {
        const isAlreadyAuth = this.isAuth()
        return (
            <div>
                {isAlreadyAuth ? <Redirect to={{pathname: '/Home'}}/> :(
                    <div>
                        <div className="container">
                            <div className="row divLogin">
                                <div className="col-md-4 offset-md-4 divLogin">
                                    <div className="px-5 py-5 bgLogin">
                                        <form onSubmit={this.handleSubmit}>
                                            <div className="form-group">
                                                <label htmlFor="user"></label>
                                                <input 
                                                    type="text" 
                                                    className="form-control" placeholder="Usuario" 
                                                    id="user"
                                                    value={this.state.name}
                                                    onChange={e => this.setState({name: e.target.value})}
                                                />
                                            </div>
                                            <div className="form-group">
                                                <label htmlFor="password"></label>
                                                <input 
                                                    type="password" 
                                                    className="form-control" placeholder="Contraseña" 
                                                    id="password"
                                                    value={this.state.pass}
                                                    onChange={e => this.setState({pass: e.target.value})}
                                                />
                                            </div>
                                            <button type="submit" className="btn btn-primary mt-1">Ingresar</button>
                                        </form>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                )}
            </div>
        );
    }
}

export default Login;