import React, { Component } from 'react';

class Prendas extends Component {
    constructor(props){
        super(props)
        this.state={
            prendas: [],
            prenda: '',
            idPrenda: 0,
            satelite: '',
            satelites: [],
            sexo: 'Hombre',
            edit: false
        }
        this.addPrenda = this.addPrenda.bind(this)
        this.getPrendas = this.getPrendas.bind(this)
        this.editPrenda = this.editPrenda.bind(this)
    }
    getPrendas(){
        var data = {
            data: 'get',
        }
        fetch('/getPrendas', {
            method: 'POST',
            body: JSON.stringify(data),
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            }
        })
            .then(res => res.json())
            .then((data) => {
                this.setState({
                    prendas: data
                })
            })
            .catch(err => console.error(err))
    }
    addPrenda(){
        var prenda = {
            prenda: this.state.prenda,
            sexo: this.state.sexo
        }
        fetch('/addPrenda', {
            method: 'POST',
            body: JSON.stringify(prenda),
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            }
        })
            .then(res => res.json())
            .then((data) => {
                this.setState({
                    prenda: '',
                    sexo: ''
                })
                this.getPrendas()
            })
            .catch(err => console.error(err))
    }
    sendEditPrenda(){
        var prenda = {
            prenda: this.state.prenda,
            sexo: this.state.sexo,
            id_prenda: this.state.idPrenda
        }
        fetch('/editPrenda', {
            method: 'POST',
            body: JSON.stringify(prenda),
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            }
        })
            .then(res => res.json())
            .then((data) => {
                this.setState({
                    prenda: '',
                    sexo: '',
                    edit: false
                })
                this.getPrendas()
            })
            .catch(err => console.error(err))
    }
    editPrenda(idPrenda, prenda){
        var dato = prenda.split(' - ')
        console.log(dato)
        this.setState({
            prenda: dato[0],
            sexo: dato[1],
            idPrenda: idPrenda,
            edit: true
        })
    }
    deletePrenda(idPrenda){
        var prenda = {
            id_prenda: idPrenda
        }
        fetch('/deletePrenda', {
            method: 'POST',
            body: JSON.stringify(prenda),
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            }
        })
            .then(res => res.json())
            .then((data) => {
                this.getPrendas()
            })
            .catch(err => console.error(err))
    }
    addSatelite(){
        var satelite = {
            satelite: this.state.satelite
        }
        fetch('/addSatelite', {
            method: 'POST',
            body: JSON.stringify(satelite),
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            }
        })
            .then(res => res.json())
            .then((data) => {
                this.setState({
                    satelite: ''
                })
                this.getSatelite()
            })
            .catch(err => console.error(err))
    }
    getSatelite(){
        var data = {
            data: 'get',
        }
        fetch('/getSatelite', {
            method: 'POST',
            body: JSON.stringify(data),
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            }
        })
            .then(res => res.json())
            .then((data) => {
                this.setState({
                    satelites: data
                })
            })
            .catch(err => console.error(err))
    }
    deleteSatelite(idSatelite){
        var satelite = {
            id_satelite: idSatelite
        }
        fetch('/deleteSatelite', {
            method: 'POST',
            body: JSON.stringify(satelite),
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            }
        })
            .then(res => res.json())
            .then((data) => {
                this.getSatelite()
            })
            .catch(err => console.error(err))
    }
    componentDidMount(){
        this.getPrendas()
        this.getSatelite()
    }
    render() {
        return (
            <div className="container mt-4 pt-3 recuadro">
                <div className="row">
                    <div className="form-group col-md-4">
                        <label htmlFor="prenda">Agregar prendas</label>
                        <input 
                            type="text" 
                            className="form-control" placeholder="Prenda" 
                            id="prenda"
                            value={this.state.prenda}
                            onChange={e => this.setState({prenda: e.target.value})}
                        />
                    </div>
                    <div className="form-group col-md-2">
                        <label htmlFor="prsexoenda">Sexo</label>
                        <select 
                            className="form-control" 
                            id="sexo"
                            value={this.state.sexo}
                            onChange={e => this.setState({sexo: e.target.value})}
                        >
                            <option>Hombre</option>
                            <option>Mujer</option>
                            <option>Varios</option>
                        </select>
                        <div className="text-right">
                            {!this.state.edit ?
                                <button className="btn btn-outline-info mt-2" onClick={() => this.addPrenda()}>Agregar Prenda</button> : <button className="btn btn-outline-warning mt-2" onClick={() => this.sendEditPrenda()}>Editar Prenda</button>
                            }
                        </div>
                    </div>
                    <div className="form-group col-md-6">
                        <label htmlFor="satelite">Agregar satelites</label>
                        <input 
                            type="text" 
                            className="form-control" placeholder="satelite" 
                            id="satelite"
                            value={this.state.satelite}
                            onChange={e => this.setState({satelite: e.target.value})}
                        />
                        <div className="text-right">
                            <button className="btn btn-outline-primary mt-2" onClick={() => this.addSatelite()}>Agregar Satelite</button>
                        </div>
                    </div>
                </div>
                <div className="row">
                    <div className="col-md-6">
                        <table className="table">
                            <thead>
                                <tr>
                                    <th>Prenda</th>
                                    <th>Editar</th>
                                    <th>Eliminar</th>
                                </tr>
                            </thead>
                            <tbody>
                                {this.state.prendas.map(prenda => {
                                    return(
                                        <tr key={prenda.id_prenda}>
                                            <td>{prenda.prenda}</td>
                                            <td><button onClick={() => this.editPrenda(prenda.id_prenda, prenda.prenda)}><i className="fas fa-edit text-warning"></i></button></td>
                                            <td><button onClick={() => this.deletePrenda(prenda.id_prenda)}><i className="fas fa-trash text-danger"></i></button></td>
                                        </tr>
                                    )
                                })}
                            </tbody>
                        </table>
                    </div>

                    <div className="col-md-6">
                        <table className="table">
                            <thead>
                                <tr>
                                    <th>Satelite</th>
                                    <th>Eliminar</th>
                                </tr>
                            </thead>
                            <tbody>
                                {this.state.satelites.map(satelite => {
                                    return(
                                        <tr key={satelite.id_satelite}>
                                            <td>{satelite.satelite}</td>
                                            <td><button onClick={() => this.deleteSatelite(satelite.id_satelite)}><i className="fas fa-trash text-danger"></i></button></td>
                                        </tr>
                                    )
                                })}
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
        );
    }
}

export default Prendas;